import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AttachmentsEditModalComponent } from '@components/attachments-edit-modal/attachments-edit-modal.component';
import { ModalWindowComponent } from '@components/modal-window/modal-window.component';
import { AssociatedType } from '@constants/enums/associated-type.enum';
import { EntityState } from '@constants/enums/entity-state';
import { ApiEntityTypesEnum } from '@constants/enums/entity-types.enum';
import { ParameterTypeEnum } from '@constants/enums/ParameterTypeEnum';
import { environment } from '@env/environment';
import { LoggedInUserInfo } from '@env/LoggedInUserInfo';
import { AttachmentFile } from '@models/attachments-file.model';
import { ApiFactory } from '@services/core/api-factory.class';
import { LoggerService } from '@services/core/logger-service.class';
import { FilterExpressionBuilder } from '@services/core/models/Filter-Entry';
import { booleanToString, isValidArray, processBlobResponse } from '@utilities/helpers';

require('formdata-polyfill');

@Component({
  selector: 'app-attachments',
  templateUrl: './attachments.component.html',
  styleUrls: ['./attachments.component.scss'],
})
export class AttachmentsComponent implements OnInit {
  attachments: AttachmentFile[] = [];
  entityState = EntityState;
  showAlert = false;
  errorMessage = 'An error occurred uploading the file, please try again later.';

  @Input() title: string;
  // View mode performs the operations right away while edit mode needs an overall saving trigger
  @Input() isEditMode = false;
  @Input() addAuthFeature: string;
  @Input() downloadAuthFeature: string;
  @Input() parentEntity: any;
  private _associatedId: string;
  get associatedId(): string { return this._associatedId; }
  @Input()
  set associatedId(value: string) {
    this._associatedId = value;
    this.loadData();
  }

  private _associatedTypeId: AssociatedType;
  get associatedTypeId(): AssociatedType { return this._associatedTypeId; }
  @Input()
  set associatedTypeId(value: AssociatedType) {
    this._associatedTypeId = value;
    this.loadData();
  }

  @Output() onAttachments = new EventEmitter<AttachmentFile[]>();

  @ViewChild(AttachmentsEditModalComponent, { static: true }) editModal: AttachmentsEditModalComponent;
  @ViewChild(ModalWindowComponent, { static: true }) deleteModal: ModalWindowComponent;

 constructor() {
 }

  ngOnInit() {
    ($('body') as any).tooltip({ selector: '[data-bs-toggle="tooltip"]' });
  }

  openEditModal(attachmentId = '') {
    this.editModal.data = this.attachments;
    this.editModal.show(attachmentId);
  }

  openDeleteModal(attachmentId = '') {
    this.deleteModal.data = attachmentId;
    this.deleteModal.show();
  }

  onDeleteModalConfirm(attachmentId: string) {
    if (!this.isEditMode) {
      this.deleteAttachment(attachmentId);
    } else {
      this.removeLocalAttachment(attachmentId);
    }
  }

  onAttachmentChange(attachment: AttachmentFile) {
    attachment.companyId = LoggedInUserInfo.Instance.userInfo.companyId.toString();
    attachment.createdByUserId = LoggedInUserInfo.Instance.userInfo.userId.toString();
    attachment.associatedId = this.associatedId;
    attachment.associatedTypeId = this.associatedTypeId;

    if (!this.isEditMode) {
      this.saveAttachment(attachment);
      return;
    }

    switch (attachment.entityState) {
      case EntityState.Added: {
        this.addLocalAttachment(attachment);
        break;
      }
      case EntityState.Modified: {
        this.updateLocalAttachment(attachment);
        break;
      }
      case EntityState.Deleted: {
        this.removeLocalAttachment(attachment.id);
        break;
      }
      default: {
        this.throwEntityStateNotSupportedError(attachment.entityState);
      }
    }
  }

  isEmptyState() {
    return !(this.attachments && this.attachments.length && this.attachments.some((x) => !x.isDeleted));
  }

  download(attachment) {
    ApiFactory.retrieveEntity(ApiEntityTypesEnum.Attachment)
      .addRouteHint('Download')
      .addEntityId(attachment.id)
      .addSuccessHandler((x) => processBlobResponse(x, attachment.openAsDownload))
      .buildAndSend();
  }

  async saveAll(associatedId: string) {
    if (!isValidArray(this.attachments)) { return; }

    const attachmentsChanges = this.attachments.filter((x) => x.entityState !== EntityState.Unchanged);
    if (isValidArray(attachmentsChanges)) {
      const saveProcesses = attachmentsChanges.map(async (x) => {
        x.associatedId = associatedId;
        if (x.entityState === EntityState.Added || x.entityState === EntityState.Modified) {
          await this.saveAttachment(x);
        } else if (x.entityState === EntityState.Deleted) {
          await this.deleteAttachment(x.id);
        } else {
          this.throwEntityStateNotSupportedError(x.entityState);
        }
      });
      await Promise.all(saveProcesses)
      .catch(function(err) {
        throw(err);
      });
    }
  }

  private loadData() {
    // Loading associated attachments
    if (this.associatedId && this.associatedTypeId) {
      const associatedIdEx = FilterExpressionBuilder.For(ApiEntityTypesEnum.Attachment)
        .Use('AssociatedId', ParameterTypeEnum.String)
        .Equal(this.associatedId)
        .Build().AsExpression;

      const associatedIdTypeEx = FilterExpressionBuilder.For(ApiEntityTypesEnum.Attachment)
        .Use('AssociatedTypeId', ParameterTypeEnum.String)
        .Equal(this.associatedTypeId)
        .Build().AsExpression;

      ApiFactory.retrieveEntity(ApiEntityTypesEnum.Attachment)
        .addFilterEntries(associatedIdEx)
        .addFilterEntries(associatedIdTypeEx)
        .addSuccessHandler((response: AttachmentFile[]) => {
          this.attachments = response;
          this.attachments.forEach((x) => this.fixAttachment(x));
          this.sortAttachments();
        })
        .removePaging()
        .buildAndSend();
    }
  }

  private saveAttachment(attachment: AttachmentFile): Promise<void> {
    let apiFactory: ApiFactory;
    if (attachment.entityState === EntityState.Added) {
      const formData = this.buildFormData(attachment);
      apiFactory = ApiFactory.saveNewEntity(ApiEntityTypesEnum.Attachment, formData);
    } else if (attachment.entityState === EntityState.Modified) {
      apiFactory = ApiFactory.updateEntity(ApiEntityTypesEnum.Attachment, attachment);
    } else {
      this.throwEntityStateNotSupportedError(attachment.entityState);
    }

    return new Promise<void>((resolve, reject) => {
      this.editModal.isDisabled = true;
      apiFactory.addSuccessHandler((response: AttachmentFile) => {
        this.fixAttachment(response);
        this.removeLocalAttachment(attachment.id);
        this.addLocalAttachment(response);
        this.dismissAlert();
        resolve();
      }).addErrorHandler((error) => {
        LoggerService.trace('trace', error);
        if (error && error.errorData) {
          this.errorMessage = error.errorData[0].exceptionData.toString();
        }
        this.hideEditModal();
        this.showAlert = true;
        reject(this.errorMessage);
      })
        .buildAndSend();
    });
  }

  private hideEditModal() {
    this.editModal.close();
    this.editModal.isDisabled = false;
  }

  dismissAlert() {
    this.showAlert = false;
  }

  private deleteAttachment(attachmentId: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      ApiFactory.deleteEntity(ApiEntityTypesEnum.Attachment)
        .addEntityId(attachmentId)
        .addSuccessHandler(() => {
          this.removeLocalAttachment(attachmentId);
          resolve();
        })
        .addErrorHandler(() => {
          resolve();
        })
        .buildAndSend();
    });
  }

  private addLocalAttachment(attachment: AttachmentFile) {
    this.attachments.push(attachment);
    this.sortAttachments();
    this.hideEditModal();
    this.onAttachments.emit(this.attachments);
  }

  private updateLocalAttachment(attachment: AttachmentFile) {
    this.attachments = this.attachments.filter((x) => x.id !== attachment.id);
    this.addLocalAttachment(attachment);
  }

  private removeLocalAttachment(attachmentId: string) {
    const removedOne = this.attachments.find((x) => x.id === attachmentId);
    if (!removedOne) { return; }

    removedOne.isDeleted = true;
    removedOne.entityState = EntityState.Deleted;
    this.updateLocalAttachment(removedOne);
  }

  private throwEntityStateNotSupportedError(state: EntityState) {
    throw new Error(`EntityState: ${state} not supported`);
  }

  private buildFormData(attachment: AttachmentFile): FormData {
    const data = new FormData();
    data.append('id', attachment.entityState === EntityState.Added ? undefined : attachment.id);
    data.append('companyId', attachment.companyId);
    data.append('associatedId', attachment.associatedId);
    data.append('associatedTypeId', attachment.associatedTypeId);
    data.append('file', attachment.file);
    data.append('fileName', attachment.fileName);
    data.append('description', attachment.description);
    data.append('isDeleted', booleanToString(attachment.isDeleted)); // FormData accept just strings
    data.append('createdByUserId', attachment.createdByUserId);

    return data;
  }

  private sortAttachments(): AttachmentFile[] {
    return this.attachments.sort((a, b) => a.fileName.localeCompare(b.fileName));
  }

  private fixAttachment(x: AttachmentFile) {
    // Downloads should happen through the Api
    x.fileUrl = `${environment.jjkellerPortalApiRootUrl}api/v${environment.apiVersion}/${x.companyId}/${x.createdByUserId}/Attachment/${x.id}/Download`;
    x.entityState = EntityState.Unchanged;
    x.groupId = this.parentEntity.groupId;
  }
}

import { Injectable } from '@angular/core';
import { AssociatedType } from '@constants/enums/associated-type.enum';
import { ApiEntityTypesEnum } from '@constants/enums/entity-types.enum';
import { ParameterTypeEnum } from '@constants/enums/ParameterTypeEnum';
import { AttachmentFile } from '@models/attachments-file.model';
import { isNullOrUndefined } from '@utilities/helpers';
import { ApiFactory } from './core/api-factory.class';
import { FilterExpressionBuilder } from './core/models/Filter-Entry';

@Injectable()
export class AttachmentService {

    constructor() {
    }

    async getAttachment(associatedObjectId: string, associatedTypeId: AssociatedType) : Promise<AttachmentFile> {

        const associatedIdEx = FilterExpressionBuilder.For(ApiEntityTypesEnum.Attachment)
        .Use('AssociatedId', ParameterTypeEnum.String)
        .Equal(associatedObjectId)
        .Build().AsExpression;

        const associatedIdTypeEx = FilterExpressionBuilder.For(ApiEntityTypesEnum.Attachment)
        .Use('AssociatedTypeId', ParameterTypeEnum.String)
        .Equal(associatedTypeId)
        .Build().AsExpression;

        return new Promise((resolve, reject) => {
            ApiFactory.retrieveEntity(ApiEntityTypesEnum.Attachment)
            .addFilterEntries(associatedIdEx)
            .addFilterEntries(associatedIdTypeEx)
            .addSuccessHandler((response: AttachmentFile) => {
                resolve(response);
            })
            .addErrorHandler(() => {
                reject();
            })
            .removePaging()
            .buildAndSend();
        });
    }

    async createAttachment(fileToSave: any) : Promise<AttachmentFile>  {
        if (isNullOrUndefined(fileToSave)) return null;
        return new Promise((resolve, reject) => {
        ApiFactory.saveNewEntity(ApiEntityTypesEnum.Attachment, fileToSave)
            .addSuccessHandler((response: AttachmentFile) => {
                resolve(response);
            })
            .addErrorHandler((error) => {
                let errorMessage = 'An error occurred uploading the file, please try again later.';
                if (error && error.errorData) {
                    errorMessage = error.errorData[0].exceptionData.toString();
                }
                reject(errorMessage);
            })
            .buildAndSend();
        });
    }

    async deleteAttachment(attachmentId: string): Promise<void> {
        if (isNullOrUndefined(attachmentId)) return null;
        return new Promise<void>((resolve, reject) => {
        ApiFactory.deleteEntity(ApiEntityTypesEnum.Attachment)
            .addEntityId(attachmentId)
            .addSuccessHandler(() => {
                resolve();
            })
            .addErrorHandler(() => {
                reject();
            })
            .buildAndSend();
        });
    }
}

import { EntityState } from '@constants/enums/entity-state';
import { Attachment } from './entity-models/autogenerated/attachment';
import { IHasFormFile } from './has-form-file';

export class AttachmentFile extends Attachment implements IHasFormFile {
    file: any;
    entityState: EntityState;
    sectionNumber: any;
    questionNumber: any;
}

import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormGroup, ValidationErrors } from '@angular/forms';
import { GridApi, GridReadyEvent } from 'ag-grid-community';
import { Guid } from 'guid-typescript';
import { AGGridActionsCustomColumnComponent } from '@components/ag-grid-actions-custom-column/ag-grid-actions-custom-column.component';
import { AddEditHazardFormComponent } from '@pages/layout/layout-slide-out-modal.component/slide-out-modal-forms/add-edit-hazard-form.component';
import { AddEditIngredientFormComponent } from '@pages/layout/layout-slide-out-modal.component/slide-out-modal-forms/add-edit-ingredient-form.component';
import { AddEditStatementsFormComponent } from '@pages/layout/layout-slide-out-modal.component/slide-out-modal-forms/add-edit-statement-hazard-form.component';
import { SlideOutModalComponent } from '@pages/layout/layout-slide-out-modal.component/slide-out-modal.component';
import { isNullOrUndefined } from '@utilities/helpers';
import { LoggerService } from '@services/core/logger-service.class';
import { now } from 'moment';

@Component({
  selector: 'ag-grid-simple',
  templateUrl: './ag-grid-simple.component.html',
  styleUrls: ['./ag-grid-simple.component.scss'],
})
export class AGGridSimpleComponent implements OnInit {
  context: any;
  gridReady = false;
  gridApi: GridApi;
  gridRowData: any[] = [];
  height = '100vh';
  minHeightWithActionColumn = '300px';
  otherText = '';
  overlayLoadingTemplate = '<span class="ag-overlay-loading-center">Fetching data...</span>';

  defaultColDef = {
    flex: 1,
    minWidth: 150,
    sortable: false,
    filter: false,
  };

  style = {
    width: '100%',
    height: '100%',
    flex: '1 1 auto',
  };

  private _rowData: any[];

  private _actionsChildComponent: any = '';
  record: any;
  simpleGrid: any;
  RandomRecord: any;

  public get actionsChildComponent() {
    return this._actionsChildComponent;
  }
  public set actionsChildComponent(value) {
    if (!isNullOrUndefined(this.actionsChildComponent)) {
      this._actionsChildComponent = value;
    }
  }
  @ViewChild(AddEditHazardFormComponent) hazardForm: AddEditHazardFormComponent;
  @ViewChild(AddEditStatementsFormComponent) statementForm: AddEditStatementsFormComponent;
  @ViewChild(AddEditIngredientFormComponent) ingredientForm: AddEditIngredientFormComponent;
  @ViewChild(SlideOutModalComponent) slideOut: SlideOutModalComponent;
  @Output() newRowData: any[];
  @Output() childComponentClick = new EventEmitter();
  @Output() pushDataToParentData = new EventEmitter<any>();
  @Input() objName: string;
  @Input() relevantId: string;
  @Input() columnDefinition: any[];
  @Input() maxHeight = '150px';
  @Input() actionMenuColumn: any[];
  @Input() showActionMenuColumn = true;
  @Input() slideoutModal = 0;
  @Input() overlayNoRowsTemplate = '<span class="ag-overlay-loading-center">Your search did not return any results...</span>';
  isEdit = false;
  isAdd = false;
  isView = false;
  isDelete = false;
  confirmButtonText = '';
  isUpdateData = false;
  keepButtonEnabled = true;
  rowToEdit: any;
  currentRecordIndex: any;
  updatedCurrentRecord: any;
  compForm: any;

  @Input()
  set rowData(tableData: any[]) {
    this._rowData = tableData;
    this.loadData();
  }
  get rowData() {
    return this._rowData;
  }

  // AGGrid Custom Components
  components = {
    agGridActionsCustomColumnComponent: AGGridActionsCustomColumnComponent,
  };

  constructor() {
    this.context = { componentParent: this };
  }

  ngOnInit(): void {
    this.setCompForm();
  }

  setCompForm() {
    switch (Number(this.slideoutModal)) {
      case 1:
        this.compForm = this.hazardForm;
        break;
      case 2:
        this.compForm = this.statementForm;
        break;
      case 3:
        this.compForm = this.ingredientForm;
        break;
    }
  }

  async onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
    this.gridApi.showLoadingOverlay();
    this.setColsDef();
    this.gridReady = true;
    this.loadData();
  }

  getFormErrors(formGroup: UntypedFormGroup): string[] {
    let errors: string[] = [];
    Object.keys(formGroup.controls).forEach((key) => {
      const controlErrors: ValidationErrors = formGroup.get(key).errors;
      if (controlErrors != null) {
        errors = errors.concat(Object.keys(controlErrors));
      }
    });
    return errors;
  }

  refreshGrid(close: boolean) {
    const start = now();

    if(this.isView) {
      this.slideOut.close();
      return;
    }

    let action = '';

    if(!this.compForm.form.valid){
      this.compForm.showErrors = true;
      return;
    }
    this.keepButtonEnabled = false;
    this.RandomRecord = this.compForm.form.value;
    this.RandomRecord.isDeleted = this.isDelete;
    this.RandomRecord.wasModified = true;

    if (this.isDelete || this.isEdit) {
      LoggerService.trace('trace', `SDS management: refresh grid isEdit or isDelete ` + this.RandomRecord.id);
      this.rowToEdit = this.gridApi.getRowNode(this.actionsChildComponent.rowIndex);
      this.gridRowData[this.actionsChildComponent.rowIndex] = this.RandomRecord;
      this.rowToEdit.setData(this.RandomRecord);
    }

    if (this.isDelete) {
      action = 'Delete';
      this.gridRowData.splice(this.actionsChildComponent.rowIndex, 1);
      this.gridApi.setGridOption("rowData", this.gridRowData.filter(d => d.isDeleted == false));
    }
    else if (this.isEdit) {
      action = 'Edit';
      LoggerService.trace('trace', `SDS management: refresh grid isEdit ` + this.RandomRecord.id);
    }
    else {
      action = 'Add';
      LoggerService.trace('trace', `SDS management: refresh grid isAdd ` + this.RandomRecord.id);
      this.RandomRecord.id = Guid.create().toString();
      this.RandomRecord.isNew = true;
      this.gridRowData = [...this.gridRowData, this.RandomRecord];
      this.gridApi.setGridOption("rowData", this.gridRowData);
    }

    this.pushDataToParentData.emit({
      data: this.RandomRecord,
      action: action
    });

    if(close) {
      this.clearEditObjects();
      this.slideOut.close();
    }
    else
      this.setUpPage(action);
      const elapsed = (beginning = start, log = false) => {
        const duration = new Date().getTime() - beginning;
        if (log) {
          LoggerService.trace('trace', `SDS management: refresh grid ' + ${duration / 1000}s`);
        }
      }
  }

  clearEditObjects() {
    this.rowData = null;
    this.newRowData = null;
    this.rowToEdit = null;
    this.RandomRecord = null;

    this.compForm.clearForm();

  }

  loadData() {
    if (!this.gridReady) { return; }
    if (isNullOrUndefined(this.rowData)) { return; }
    this.gridRowData = [];
    this.gridRowData.push(...this.rowData.filter(d => d.isDeleted == false));
    if(!this.isUpdateData){
      this.isUpdateData = true;
      this.gridApi.applyTransaction({ add: this.gridRowData});
    }
    this.gridApi.hideOverlay();
  }

  setColsDef() {
    this.columnDefinition.forEach(col => {
      col.flex = 1;
      col.padding = 10;
      col.width = isNullOrUndefined(col.width) ? 150 : col.width;
      col.minWidth = 150;
      col.suppressMenu = true;
      col.resizable = true;
      col.sortable = false;
      col.filter = false;
      col.suppressMovable = true;
    });

    if (this.showActionMenuColumn) {
      if ((this.actionMenuColumn?.length > 0))
        this.addActionMenuColumn();
    } else {
      this.minHeightWithActionColumn = this.maxHeight;
    }

    this.gridApi.setGridOption("columnDefs", this.columnDefinition);
  }

  // Action Menu Column
  addActionMenuColumn() {
    this.columnDefinition.unshift({
      headerName: 'Menu',
      width: 90,
      minWidth: 90,
      maxWidth: 90,
      filter: false,
      suppressMovable: true,
      colId: 'ActionsColumn',
      cellRenderer: 'agGridActionsCustomColumnComponent',
      cellClass: 'actions-button-cell bold',
      cellRendererParams: {
        customColumnParams: this.actionMenuColumn,
        action: this.gridActionComponentEvent,
      },
      suppressColumnsToolPanel: true,
      suppressFiltersToolPanel: true,
      lockPosition: true,
      suppressMenu: true,
    });
  }

  gridActionComponentEvent(params) {
    params.context.componentParent.actionsChildComponent = params;
    params.context.componentParent.openModal('#' + params.context.componentParent.objName + '_slideoutmodal', params.ChildComponentAction);
  }

  openModal(name: string, action: string) {
    if (this.actionsChildComponent) {
      this.record = this.actionsChildComponent.data;
    }

    if (isNullOrUndefined(this.compForm)) {
      this.setCompForm();
    }

    this.isView = action === 'View';
    this.isEdit = action === 'Edit';
    this.isDelete = action === 'Delete';
    this.isAdd = action === 'Add';

    setTimeout(() => {
      this.setUpPage(action);
      ($(name) as any).appendTo("body").modal('show');
    }, 50);
  }

  setUpPage(action: string) {
    this.keepButtonEnabled = true;
    if(!isNullOrUndefined(this.compForm)){this.compForm.showErrors = false;}
    switch (action) {
      case 'View':
        this.confirmButtonText = 'Ok';
        this.compForm.setUpViewPage();
        break;
      case 'Add':
        this.confirmButtonText = 'Add & Close';
        this.compForm.setUpAddPage();
        break;
      case 'Edit':
        this.confirmButtonText = 'Save & Close';
        this.compForm.setUpEditPage();
        break;
      case 'Delete':
        this.confirmButtonText = 'Delete';
        this.compForm.setUpDeletePage();
        break;
    }
  }

}
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { EditorialContentTypeEnum } from '@constants/editorial/editorial-content-type.enum';
import { EditorialTemplateTypeEnum } from '@constants/editorial/editorial-template-type.enum';
import { AgGridPredefinedState } from '@constants/enums/ag-grid-predefined-state.enum';
import { ApiEntityTypesEnum } from '@constants/enums/entity-types.enum';
import { UserTypes } from '@constants/enums/user-type.enum';
import { ACTIONS, DATAKEYS, TOPICS } from '@constants/messages.constants';
import { TOGGLES } from '@constants/toggles';
import { TREATMENT } from '@constants/treatment';
import { environment } from '@env/environment';
import { LoggedInUserInfo } from '@env/LoggedInUserInfo';
import { JJKUserInfo } from '@models/core/JJKUserInfo.class';
import { HomeNotifications } from '@models/entity-models/autogenerated/homenotifications';
import { EditorialMetaDataIndexResult } from '@models/entity-models/editorial-asa/editorial-metadata-index-result.model';
import { NewsGridViewModel } from '@models/entity-models/news/news-grid-view.model';
import { EnableMessageProcessingDirective } from '@modules/messaging/baseClasses/EnableMessageProcessing';
import { IMsgProcessingReg } from '@modules/messaging/baseClasses/MessageBusConfigurationBuilder';
import { MessageTypeEnum } from '@modules/messaging/baseClasses/MessageTypeEnum';
import { MessageBusService } from '@modules/messaging/services/messageBusService';
import { SystemEventMessage } from '@modules/messaging/types/Messages/SystemEventMessage';
import { ApiFactory } from '@services/core/api-factory.class';
import { SetTitleService } from '@services/set-title.service';
import { SplitioService } from '@services/splitio.service';
import { getEditorialFilter, getMiniGridFilters, getMiniGridOrderFilter, getMiniGridTopFilter, mapEditorialDataToNewsGridViewModel, validDateTime } from '@utilities/editorialAsaHelper';
import { getAGGridPredefinedStateQueryString } from '@utilities/filters-helper';
import { calculateAvailableEnrollments, calculateEnrollmentsExpiringSoon, checkSSO, unsubscribeFromAll } from '@utilities/helpers';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styles: ['.coming-soon {display: flex; height: 200px; background-color: #efeeed; align-items: center; justify-content: center; }', '.coming-soon p { font-size: xx-large; }'],
})
export class DashboardComponent extends EnableMessageProcessingDirective implements OnInit, OnDestroy {
    title = 'Dashboard';
    isAuthorized: boolean;
    currentUser = { name: null };
    homeNotifications: HomeNotifications;
    expertHelpResponseCount: number;
    enrollmentsAvailableCount: number;
    enrollmentsExpiringSoonCount: number;
    subscriptions$: Subscription[] = [];
    // This option is necessary for marketing to test their html/css before pushing it to prod
    // since both the app's dev and prod environments are hooked up to the pimcore prod instance
    isDevEnvironment = environment.environmentName === 'environmentDev';
    isSdsManagement = LoggedInUserInfo.Instance.userInfo.user.userType === UserTypes.SdsMgmt;
    
    siteUpdateData: NewsGridViewModel[];
    newsData: NewsGridViewModel[];
    changeNoticeData: NewsGridViewModel[];
    loadingChangeNotices = false;
    loadingNews = false;
    loadingNotifications = true;
    loadingExpertHelpResponseCount = true;
    loadingSiteUpdates = false;
    loadingEnrollmentsAvailable = true;
    showSubtitle = false;
    enrollmentsRouterLink = "/training/enrollment_orders";

    newSdsQueryParams: any;
    openIncidentsQueryParams: any;
    openTasksQueryParams: any;
    pastDueAuditsQueryParams: any;

    taskBreakdownEmptyStateMessage = 'Add a Task to view a chart that visually represents your Safety Management Suite data.';
    completedTaskEmptyStateMessage = 'Complete a Task to view a chart that visually represents your Safety Management Suite data.';

    constructor(
        private titleService: SetTitleService,
        mb: MessageBusService,
        private router: Router,
        private splitioService: SplitioService) {
            
        super(mb);
    }

    ngOnDestroy() {
        unsubscribeFromAll(this.subscriptions$);
    }

    protected configureMessageBus(builder: IMsgProcessingReg): void {
        builder
            .add()
            .inBoundDataTopic(TOPICS.APPLEVEL.SYSTEM)
            .listenForMessageType(MessageTypeEnum.SystemEventMessage)
            .withAction(ACTIONS.AUTH.AUTH_LOGIN_SUCCESS)
            .thenCallFunction((f) => this.updateUserData(f));
    }

    async ngOnInit() {
        super.ngOnInit();
        window.scrollTo(0, 0);
        this.titleService.setTitle(this.title);
        if(await this.splitioService.getToggle(TOGGLES.AEM_IMPLEMENTATION) === TREATMENT.ON) {this.loadAEM();}
        this.updateUserData();

        if (!this.isSdsManagement) {
            this.setNotificationQueryParams();
            this.loadNotifications();
            this.loadSiteUpdates();
            this.loadNews();
            this.loadChangeNotices();
        }
    }
    private loadAEM() {

        ApiFactory.retrieveEntity(ApiEntityTypesEnum.AEM)
        .addDataEntry("currentURL", this.router.url)
        .addSuccessHandler((response) => {
            (window as any).digitalData = [];
           $("head").append(response.header);
           $("body").append(response.footer);
           (window as any).digitalData.push(response.pageData);
        })
        .addErrorHandler((response) => {
            console.error(response.errorData);
        })
        .removePaging()
        .buildAndSend();
    }

    updateUserData(data: SystemEventMessage = null) {
        const user = data && data.messageData[DATAKEYS.APPLEVEL.SECURITY.USER_DATA] as JJKUserInfo;
        this.currentUser.name = LoggedInUserInfo.Instance.userInfo.fullName || (data && user.fullName) || '';
        this.isAuthorized = LoggedInUserInfo.Instance.isAuthorized;
    }

    private setNotificationQueryParams() {
        this.newSdsQueryParams = getAGGridPredefinedStateQueryString(AgGridPredefinedState.NewChemicalSDS);
        this.openIncidentsQueryParams = getAGGridPredefinedStateQueryString(AgGridPredefinedState.OpenIncidents);
        this.openTasksQueryParams = getAGGridPredefinedStateQueryString(AgGridPredefinedState.OpenTasks);
        this.pastDueAuditsQueryParams = getAGGridPredefinedStateQueryString(AgGridPredefinedState.PastDueAudits);
    }

    private loadNotifications() {
        this.loadSmsNotificationData();
        this.loadTodNotificationData();
    }

    private loadSmsNotificationData() {
        ApiFactory.retrieveEntity(ApiEntityTypesEnum.Dashboard)
        .addRouteHint('GetDashboardCounts')
        .addDataEntry('userId', LoggedInUserInfo.Instance.userInfo.userId)
        .addDataEntry('companyId', LoggedInUserInfo.Instance.userInfo.companyId)
        .addDataEntry('pageName', 'home')
        .addSuccessHandler((response: HomeNotifications) => {
            this.homeNotifications = response;
            this.loadingNotifications = false;
        })
        .addErrorHandler((response) => {
            console.error(response.errorData);
        })
        .removePaging()
        .buildAndSend();
    }

    private loadTodNotificationData() {
        ApiFactory.retrieveEntity(ApiEntityTypesEnum.Order)
            .addSuccessHandler((orders) => {
                if (orders) {
                    this.enrollmentsAvailableCount = calculateAvailableEnrollments(orders);
                    this.enrollmentsExpiringSoonCount = calculateEnrollmentsExpiringSoon(orders);
                    this.showSubtitle = this.enrollmentsExpiringSoonCount > 0;
                    this.loadingEnrollmentsAvailable = false;
                } else {
                    this.handleTodNotificationsError();
                }
            })
            .addErrorHandler(() => this.handleTodNotificationsError())
            .removePaging()
            .buildAndSend();
    }

    private handleTodNotificationsError() {
        this.enrollmentsAvailableCount = 0;
        this.loadingEnrollmentsAvailable = false;
    }

    private loadSiteUpdates() {
        const filter = getEditorialFilter(EditorialTemplateTypeEnum.News, EditorialContentTypeEnum.SiteUpdates);

        this.loadingSiteUpdates = true;

        ApiFactory.retrieveEntity(ApiEntityTypesEnum.EditorialAsa)
            .addRouteHint('EditorialContent')
            .addFilterEntries(getMiniGridFilters())
            .addFilterEntries(filter)
            .addSuccessHandler((d: EditorialMetaDataIndexResult) => {
                this.siteUpdateData = mapEditorialDataToNewsGridViewModel(d.value);
                this.loadingSiteUpdates = false;
            })
            .removePaging()
            .buildAndSend();
    }

    private loadNews() {
        const filter = getEditorialFilter(EditorialTemplateTypeEnum.News, EditorialContentTypeEnum.IndustryNews);

        this.loadingNews = true;

        ApiFactory.retrieveEntity(ApiEntityTypesEnum.EditorialAsa)
            .addRouteHint('EditorialContent')
            .addFilterEntries(getMiniGridFilters())
            .addFilterEntries(filter)
            .addSuccessHandler((d: EditorialMetaDataIndexResult) => {
                this.newsData = mapEditorialDataToNewsGridViewModel(d.value);
                this.loadingNews = false;
            })
            .removePaging()
            .buildAndSend();
    }

    private loadChangeNotices() {
        const filter = getEditorialFilter(EditorialTemplateTypeEnum.News, EditorialContentTypeEnum.ChangeNotices);

        this.loadingChangeNotices = true;

        ApiFactory.retrieveEntity(ApiEntityTypesEnum.EditorialAsa)
            .addRouteHint('EditorialContent')
            .addFilterEntries(filter)
            .addFilterEntries(getMiniGridOrderFilter())
            .addFilterEntries(getMiniGridTopFilter())
            .addSuccessHandler((d: EditorialMetaDataIndexResult) => {

                d.value.sort((a , b) => {
                    return validDateTime(b.publishDate) - validDateTime(a.publishDate);
                });

                this.changeNoticeData = mapEditorialDataToNewsGridViewModel(d.value);
                this.loadingChangeNotices = false;
            })
            .removePaging()
            .buildAndSend();

    }

    async onExpertHelpClick() {
        checkSSO('Expert Help-Zendesk');
    }

    navigateToTasks(isAdd = false) {
        if (isAdd) {
            this.router.navigate([`/tasks/add`]);
        } else {
            this.router.navigate([`/tasks`]);
        }
    }
}

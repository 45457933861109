export enum ApiEntityTypesEnum {
    UNKNOWN = '__UNKNOWN__',
    OVERRIDE = 'OVERRIDE',
    AdditionalEmployeeInfo = 'AdditionalEmployeeInfo',
    AdditionalEmployeeInfoHistory = 'AdditionalEmployeeInfoHistory',
    AdditionalForm300Info = 'AdditionalForm300Info',
    AdditionalForm300InfoHistory = 'AdditionalForm300InfoHistory',
    AdditionalForm301Info = 'AdditionalForm301Info',
    AdditionalForm301InfoHistory = 'AdditionalForm301InfoHistory',
    AdditionalInfo = 'AdditionalInfo',
    AdminSettings = 'AdminSettings',
    Attachment = 'Attachment',
    AffectedBodyPart = 'AffectedBodyPart',
    AuditsChecklists = 'AuditsChecklists',
    AuditsQuestionLibrary = 'AuditsQuestionLibrary',
    AuditScheduleTemplate = 'AuditScheduleTemplate',
    AuditScheduleTemplateJunction = 'AuditScheduleTemplateJunction',
    Avatar = 'Avatar',
    Binder = 'Binder',
    BinderChemicalSafetyDataSheet = 'BinderChemicalSafetyDataSheet',
    BinderVersion = 'BinderVersion',
    BinderVersionLocation = 'BinderVersionLocation',
    BinderVersionWorkArea = 'BinderVersionWorkArea',
    BodyPart = 'BodyPart',
    Chart = 'Chart',
    Checklist = 'Checklist',
    ChecklistArea = 'ChecklistArea',
    ChecklistIndustry = 'ChecklistIndustry',
    ChecklistLocation = 'ChecklistLocation',
    ChecklistTopic = 'ChecklistTopic',
    ChecklistGoverningBody = 'ChecklistGoverningBody',
    ChecklistCitation = 'ChecklistCitation',
    ChecklistSettings = 'ChecklistSettings',
    ChecklistSection = 'ChecklistSection',
    ChecklistSectionJjkaQuestion = 'ChecklistSectionJjkaQuestion',
    ChecklistSectionQuestion = 'ChecklistSectionQuestion',
    Chemical = 'Chemical',
    ChemicalIdentifier = 'ChemicalIdentifier',
    ChemicalSettings = 'ChemicalSettings',
    ClassroomProgram = 'ClassroomProgram',
    ClassroomTrainingEvent = 'ClassroomTrainingEvent',
    Company = 'Company',
    CompanyUsage = 'CompanyUsage',
    ContactInformation = 'ContactInformation',
    ContainerUnitOfMeasure = 'ContainerUnitOfMeasure',
    ContainerType = 'ContainerType',
    CostCenter = 'CostCenter',
    Country = 'Country',
    County = 'County',
    Course = 'Course',
    CourseNotification = 'CourseNotification',
    CourseSample = 'CourseSample',
    CourseThresholdScore = 'CourseThresholdScore',
    CustomText = 'CustomText',
    DaysAwayOrDaysRestricted = 'DaysAwayOrDaysRestricted',
    Discussions = 'Discussions',
    EditorialAsa = 'EditorialAsa',
    Employee = 'Employee',
    EmployeeJobFunction = 'EmployeeJobFunction',
    EmployeeViewModel = 'EmployeeViewModel',
    EmploymentDataMonthly = 'EmploymentDataMonthly',
    EmploymentDataYear = 'EmploymentDataYear',
    EmploymentDataYearly = 'EmploymentDataYearly',
    EmploymentType = 'EmploymentType',
    Enrollment = 'Enrollment',
    Equipment = 'Equipment',
    Event = 'Event',
    EventReview = 'EventReview',
    EventReviewQuestion = 'EventReviewQuestion',
    EventReviewSection = 'EventReviewSection',
    ExpertHelp = 'ExpertHelp',
    Features = 'Features',
    FeaturesPerCompany = 'FeaturesPerCompany',
    FeaturesPerSubscription = 'FeaturesPerSubscription',
    FeaturesPerUserOrGroup = 'FeaturesPerUserOrGroup',
    Gender = 'Gender',
    GeneralInformation = 'GeneralInformation',
    GeneralInformationHistory = 'GeneralInformationHistory',
    GlobalProduct = 'GlobalProduct',
    GlobalProductViewModel = 'GlobalProductViewModel',
    GlobalSafetyDataSheet = 'GlobalSafetyDataSheet',
    Groups = 'Groups',
    GroupsRolePerUserOrGroup = 'GroupsRolePerUserOrGroup',
    Grid = 'Grid',
    GridView = 'GridView',
    GridViewState = 'GridViewState',
    HealthcareFacility = 'HealthcareFacility',
    HealthcareProfessional = 'HealthcareProfessional',
    HomeNotifications = 'HomeNotifications',
    Identifier = 'Identifier',
    IncidentSettings = 'IncidentSettings',
    Impersonations = 'Impersonations',
    ImportExport = 'ImportExport',
    ImportExportLog = 'ImportExportLog',
    Incident = 'Incident',
    IncidentIntake = 'IncidentIntake',
    IncidentReporting = 'IncidentReporting',
    IncidentStatus = 'IncidentStatus',
    IncidentType = 'IncidentType',
    IncidentViewModel = 'IncidentViewModel',
    IncidentIntakeNotifyUser = 'IncidentIntakeNotifyUser',
    Ingredient = 'Ingredient',
    InjuryCause = 'InjuryCause',
    InjuryIllnessType = 'InjuryIllnessType',
    InjuryIllnessTypes = 'InjuryIllnessTypes',
    Investigation = 'Investigation',
    InvestigationHistory = 'InvestigationHistory',
    Invitations = 'Invitations',
    JobFunction = 'JobFunction',
    JobTitle = 'JobTitle',
    Language = 'Language',
    Learner = 'Learner',
    LegacyLearnerData = 'LegacyLearnerData',
    Location = 'Location',
    LocationGridView = 'LocationGridView',
    Manufacturer = 'Manufacturer',
    Medical = 'Medical',
    MedicalAccommodationStatus = 'MedicalAccommodationStatus',
    MedicalTest = 'MedicalTest',
    MedicalTestResult = 'MedicalTestResult',
    MedicalVaccination = 'MedicalVaccination',
    NaicsCode = 'NaicsCode',
    NotificationRecipient = 'NotificationRecipient',
    NonAuth = 'NonAuth',
    Note = 'Note',
    NumberingSequenceLocation = 'NumberingSequenceLocation',
    NumberingSequenceType = 'NumberingSequenceType',
    OktaUserMigration = 'OktaUserMigration',
    Order = 'Order',
    OshaInjuryClassification = 'OshaInjuryClassification',
    PeakEmployeeCount = 'PeakEmployeeCount',
    Permissions = 'Permissions',
    PermissionsPerRole = 'PermissionsPerRole',
    PhysicalState = 'PhysicalState',
    Pimcore = 'Pimcore',
    PimcoreStatic = 'PimcoreStatic',
    PlanAndPolicy = 'PlanAndPolicy',
    PlanAndPolicyArea = 'PlanAndPolicyArea',
    PlanAndPolicyCitation = 'PlanAndPolicyCitation',
    PlanAndPolicyGoverningBody = 'PlanAndPolicyGoverningBody',
    PlanAndPolicyIndustry = 'PlanAndPolicyIndustry',
    PlanAndPolicyTopic = 'PlanAndPolicyTopic',
    Question = 'Question',
    QuestionAvailableResponse = 'QuestionAvailableResponse',
    QuestionCorrectResponse = 'QuestionCorrectResponse',
    QuestionArea = 'QuestionArea',
    QuestionCitation = 'QuestionCitation',
    QuestionGoverningBody = 'QuestionGoverningBody',
    QuestionIndustry = 'QuestionIndustry',
    QuestionTopic = 'QuestionTopic',
    RecordableDetails = 'RecordableDetails',
    RecordableDetailsHistory = 'RecordableDetailsHistory',
    Report = 'Report',
    ReportColumn = 'ReportColumn',
    Request = 'Request',
    RequestStatus = "RequestStatus",
    Reseller = 'Reseller',
    RiskLevel = 'RiskLevel',
    Roles = 'Roles',
    SafetyDataSheet = 'SafetyDataSheet',
    SafetyDataSheetParent = 'SafetyDataSheetParent',
    SAP = 'SAP',
    Schedule = 'Schedule',
    ScoringMethod = 'ScoringMethod',
    Security = 'Security',
    Settings = 'Settings',
    Shift = 'Shift',
    State = 'State',
    StagedFiles = 'StagedFiles',
    Student = 'Student',
    Subscription = 'Subscription',
    SubscriptionDetails = 'SubscriptionDetails',
    Synonym = 'Synonym',
    Task = 'Task',
    TaskPriority = 'TaskPriority',
    TaskStatus = 'TaskStatus',
    TaskType = 'TaskType',
    TermsOfUse = 'TermsOfUse',
    TrainingAsset = 'TrainingAsset',
    TrainingRecords = 'TrainingRecords',
    UnableToSourceReason = 'UnableToSourceReason',
    User = 'User',
    UserAcceptedTerms = 'UserAcceptedTerms',
    UserDataProfile = 'UserDataProfile',
    UserGroups = 'UserGroups',
    UserGroupsMembers = 'UserGroupsMembers',
    UserGroupsRole = 'UserGroupsRole',
    UserRolesInGroup = 'UserRolesInGroup',
    UserWiseData = 'UserWiseData',
    WhereEventOccurred = 'WhereEventOccurred',
    WorkArea = 'WorkArea',
    Webcast = 'Webcast',
    MedicalVaccinationStatus = "MedicalVaccinationStatus",
    Dashboard = "Dashboard",
    GlobalSdsStatus = 'GlobalSdsStatus',
    SecurityQuestion = "SecurityQuestion",
    GlobalStatements = 'GlobalStatements',
    GlobalPersonalProtectiveEquipmentInfo = 'GlobalPersonalProtectiveEquipmentInfo',
    GlobalPersonalProtectiveEquipment = 'GlobalPersonalProtectiveEquipment',
    Hazard = 'Hazard',
    GlobalIngredient = 'GlobalIngredient',
    GlobalPictogramsInfo = 'GlobalPictogramsInfo',
    GlobalPictograms = 'GlobalPictograms',
    GlobalManufacturerSearch = 'GlobalManufacturerSearch',
    HazardCategory = "HazardCategory",
    HazardType = "HazardType",
    GlobalProportion = "GlobalProportion",
    GlobalPercentBy = "GlobalPercentBy",
    ProductMonitoringStatus = "ProductMonitoringStatus",
    GlobalSDSSearch = 'GlobalSDSSearch',
    EmploymentDataOshaSubmissionRequired = "EmploymentDataOshaSubmissionRequired",
    AEM = 'AEM',
    SafetyObservationType = "SafetyObservationType"
}

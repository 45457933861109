// AUTO-GENERATED CODE
//////////////////////////////////
// AUTO-GENERATED CODE DO NOT EDIT
//////////////////////////////////
import { AssociatedType } from './associatedtype';
import { Company } from './company';
import { User } from './user';

//////////////////////////////////
// AUTO-GENERATED CODE DO NOT EDIT
//////////////////////////////////
import { IValidatorInfo, ValidatorInfo } from '@models/validations/validatorInfo';
import { IModelRecord, IModelAudit, IModelBase } from '@models/interfaces/entity-interfaces';

//////////////////////////////////
// AUTO-GENERATED CODE DO NOT EDIT
//////////////////////////////////
export class Attachment implements IModelAudit {

//////////////////////////////////
// AUTO-GENERATED CODE DO NOT EDIT
//////////////////////////////////
        associatedId: string;
        associatedTypeId: string;
        companyId: string;
        createdByUserId: string;
        createdDate: Date;
        description: string;
        fileName: string;
        fileSize?: number;
        fileType: string;
        fileUrl: string;
        id: string;
        impersonationCreatedByUserId?: string;
        impersonationModifiedByUserId?: string;
        isDeleted: boolean;
        modifiedByUserId?: string;
        modifiedDate?: Date;
        associatedType: AssociatedType;
        createdByUser: User;
        modifiedByUser: User;
        groupId: string;
        permissions: number;

//////////////////////////////////
// AUTO-GENERATED CODE DO NOT EDIT
//////////////////////////////////
    constructor(json?: Partial<Attachment>) {
        this.associatedId = json && json.associatedId;
        this.associatedTypeId = json && json.associatedTypeId;
        this.companyId = json && json.companyId;
        this.createdByUserId = json && json.createdByUserId;
        this.createdDate = (!json || json.createdDate === undefined) ? null : (json.createdDate === null ? new Date() : json.createdDate);
        this.description = json && json.description;
        this.fileName = json && json.fileName;
        this.fileSize = json && json.fileSize;
        this.fileType = json && json.fileType;
        this.fileUrl = json && json.fileUrl;
        this.id = json && json.id;
        this.impersonationCreatedByUserId = json && json.impersonationCreatedByUserId;
        this.impersonationModifiedByUserId = json && json.impersonationModifiedByUserId;
        this.isDeleted = json && json.isDeleted;
        this.modifiedByUserId = json && json.modifiedByUserId;
        this.modifiedDate = (!json || json.modifiedDate === undefined) ? null : json.modifiedDate;
        this.associatedType = (json && new AssociatedType(json.associatedType));
        this.createdByUser = (json && new User(json.createdByUser));
        this.modifiedByUser = (json && new User(json.modifiedByUser));
        this.groupId = json && json.groupId;
        this.permissions = json && json.permissions || 0;

    }

//////////////////////////////////
// AUTO-GENERATED CODE DO NOT EDIT
//////////////////////////////////
    _validateInfo(fieldName: string): IValidatorInfo {
        switch (fieldName) {
        case 'associatedId': {
            return {
                required: false,
                minLength: undefined, maxLength: undefined,
                type: 'string',
            };
        }
        case 'associatedTypeId': {
            return {
                required: false,
                minLength: undefined, maxLength: undefined,
                type: 'string',
            };
        }
        case 'companyId': {
            return {
                required: false,
                minLength: undefined, maxLength: undefined,
                type: 'string',
            };
        }
        case 'createdByUserId': {
            return {
                required: false,
                minLength: undefined, maxLength: undefined,
                type: 'string',
            };
        }
        case 'createdDate': {
            return {
                required: false,
                minLength: undefined, maxLength: undefined,
                type: 'Date',
            };
        }
        case 'description': {
            return {
                required: false,
                minLength: undefined, maxLength: 7000,
                type: 'string',
            };
        }
        case 'fileName': {
            return {
                required: false,
                minLength: undefined, maxLength: 255,
                type: 'string',
            };
        }
        case 'fileSize': {
            return {
                required: false,
                minLength: undefined, maxLength: undefined,
                type: 'number',
            };
        }
        case 'fileType': {
            return {
                required: false,
                minLength: undefined, maxLength: 100,
                type: 'string',
            };
        }
        case 'fileUrl': {
            return {
                required: false,
                minLength: undefined, maxLength: 500,
                type: 'string',
            };
        }
        case 'id': {
            return {
                required: false,
                minLength: undefined, maxLength: undefined,
                type: 'string',
            };
        }
        case 'impersonationCreatedByUserId': {
            return {
                required: false,
                minLength: undefined, maxLength: undefined,
                type: 'string',
            };
        }
        case 'impersonationModifiedByUserId': {
            return {
                required: false,
                minLength: undefined, maxLength: undefined,
                type: 'string',
            };
        }
        case 'isDeleted': {
            return {
                required: false,
                minLength: undefined, maxLength: undefined,
                type: 'boolean',
            };
        }
        case 'modifiedByUserId': {
            return {
                required: false,
                minLength: undefined, maxLength: undefined,
                type: 'string',
            };
        }
        case 'modifiedDate': {
            return {
                required: false,
                minLength: undefined, maxLength: undefined,
                type: 'Date',
            };
        }
            default: return { required: false, minLength: undefined, maxLength: undefined, type: undefined };
        }
    }

//////////////////////////////////
// AUTO-GENERATED CODE DO NOT EDIT
//////////////////////////////////
    _validate(skip: string[] = []): [number, number, string[]?] {
        let count = 0;
        const errorFields = [];
        if (!skip.includes('associatedId') && ValidatorInfo.validateString(this.associatedId, this._validateInfo('associatedId'))) {
            count++;
            errorFields.push('associatedId');
        }
        if (!skip.includes('associatedTypeId') && ValidatorInfo.validateString(this.associatedTypeId, this._validateInfo('associatedTypeId'))) {
            count++;
            errorFields.push('associatedTypeId');
        }
        if (!skip.includes('companyId') && ValidatorInfo.validateString(this.companyId, this._validateInfo('companyId'))) {
            count++;
            errorFields.push('companyId');
        }
        if (!skip.includes('createdByUserId') && ValidatorInfo.validateString(this.createdByUserId, this._validateInfo('createdByUserId'))) {
            count++;
            errorFields.push('createdByUserId');
        }
        if (!skip.includes('createdDate') && ValidatorInfo.validateDate(this.createdDate, this._validateInfo('createdDate'))) {
            count++;
            errorFields.push('createdDate');
        }
        if (!skip.includes('description') && ValidatorInfo.validateString(this.description, this._validateInfo('description'))) {
            count++;
            errorFields.push('description');
        }
        if (!skip.includes('fileName') && ValidatorInfo.validateString(this.fileName, this._validateInfo('fileName'))) {
            count++;
            errorFields.push('fileName');
        }
        if (!skip.includes('fileSize') && ValidatorInfo.validateNumber(this.fileSize, this._validateInfo('fileSize'))) {
            count++;
            errorFields.push('fileSize');
        }
        if (!skip.includes('fileType') && ValidatorInfo.validateString(this.fileType, this._validateInfo('fileType'))) {
            count++;
            errorFields.push('fileType');
        }
        if (!skip.includes('fileUrl') && ValidatorInfo.validateString(this.fileUrl, this._validateInfo('fileUrl'))) {
            count++;
            errorFields.push('fileUrl');
        }
        if (!skip.includes('id') && ValidatorInfo.validateString(this.id, this._validateInfo('id'))) {
            count++;
            errorFields.push('id');
        }
        if (!skip.includes('impersonationCreatedByUserId') && ValidatorInfo.validateString(this.impersonationCreatedByUserId, this._validateInfo('impersonationCreatedByUserId'))) {
            count++;
            errorFields.push('impersonationCreatedByUserId');
        }
        if (!skip.includes('impersonationModifiedByUserId') && ValidatorInfo.validateString(this.impersonationModifiedByUserId, this._validateInfo('impersonationModifiedByUserId'))) {
            count++;
            errorFields.push('impersonationModifiedByUserId');
        }
        if (!skip.includes('isDeleted') && ValidatorInfo.validateString(this.isDeleted, this._validateInfo('isDeleted'))) {
            count++;
            errorFields.push('isDeleted');
        }
        if (!skip.includes('modifiedByUserId') && ValidatorInfo.validateString(this.modifiedByUserId, this._validateInfo('modifiedByUserId'))) {
            count++;
            errorFields.push('modifiedByUserId');
        }
        if (!skip.includes('modifiedDate') && ValidatorInfo.validateDate(this.modifiedDate, this._validateInfo('modifiedDate'))) {
            count++;
            errorFields.push('modifiedDate');
        }
        return [count, 8, errorFields];
    }
}
